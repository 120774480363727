<template>
         <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
      <nav class="nav nav-fill bottom-nav text-align text-center">
      <router-link to="/doctor/index" class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/index') }" aria-current="page" ><font-awesome-icon :icon="['fas', 'home']" /><p class="bottom-title">Home</p></router-link>
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/consultationdesk') }" @click="getAccess('consultation')"><font-awesome-icon :icon="['fas', 'briefcase-medical']" /><p class="bottom-title">Consultation</p></div>
  <router-link to="/doctor/community-groups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/doctor/community-groups') }" ><font-awesome-icon :icon="['fas', 'user-friends']" /><p class="bottom-title">Groups</p></router-link>              
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/report') }" @click="getAccess('report')" ><font-awesome-icon :icon="['fas', 'file-signature']" /><p class="bottom-title">Reports</p></div>
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/library') }" @click="getAccess('library')" ><font-awesome-icon :icon="['fas', 'book-medical']" /><p class="bottom-title">Library</p></div> 
      <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/invite') }" @click="getAccess('invite')"><font-awesome-icon :icon="['fas','user-plus']" /><p class="bottom-title">Invite</p></div> -->
    </nav>
</div>
</div>
    </template>
    <script>
    import axios from "axios";
    export default {
        data() {
        return {
        }},
        created: function () {
        // this.statusTrue();
      },
        methods: {
      currentPath(routePath) {
          this.$router.push(routePath);
        },
        isRouteActive(routePath) {
          return this.$route.path === routePath;
        },
        async getAccess(getvalues) {
          const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
          axios
            .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
            .then((response) => {
              if(response?.data){
                 if (response.data?.status === null) {
                  this.$swal("Please complete the About Me section of the Settings Page");
                }
                else if (response.data?.status?.status === "UnderReview") {
                  this.$swal("Your request for access is under review. Please wait.");
                }
                else if(response.data?.status?.status === "Approved"){
                  if(getvalues === 'consultation'){
                    this.$router.push({ path: '/doctor/consultationdesk' });
                  }
                  else if(getvalues==='report'){
                    this.$router.push({ path: '/doctor/report' });
                  }
                  else if(getvalues==='library'){
                    this.$router.push({ path: '/doctor/library' });
                  }
                }
                else if (response.data?.status?.status === "Rejected") {
                  this.$swal("Please fix the review comments of your request for access");
                }
              }
      
            });
        },
    
        }
    }
    </script>
    <style>
    .footer-image-aftersignup{
      margin: 0px !important;
      color: #000 !important;
      cursor: pointer;
      padding: 7px 14px;
    }
    .tab-activated {
      background-color:#00979e;
      color: #fff !important;
      margin: 0px;
    }
    </style>